<template>
    <my-layout class="compare_h5">
        <template #header>
            <div class="header">
                <img src="../../assets/img/h5/h5_minilogo.png"/>
                <span class="title_text">AI竞投-竞品PK</span>
                <span class="entrance" @click="goHome">进入官网</span>
            </div>
            <div class="search">
<!--                加form标签和method="get"属性使得输入框响应输入法的搜索按钮-->
                <form method="get" action="">
                    <van-field v-model="key_word" placeholder="请输入公司名称" @keydown.enter="changeKeyWord" type="search" @input="changeKeyWord">
                        <template #left-icon>
                            <img class="input_img" src="@/assets/img/h5/search_gray.png" alt="">
                        </template>
                    </van-field>
                </form>
            </div>
        </template>
        <template #body>
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    @load="onLoad">
                    <template #default>
                    <div class="list_item" v-for="(item, i) in tableData" :key="i" @click="changeSelected(item)">
                        <div class="item_main">
                            <div class="list_item_left">
                                <div class="company_logo" :style="{'backgroundColor': item.logo_color}">{{item.logo_img_str}}</div>
                            </div>
                            <div class="list_item_right">
                                <div class="company_name">
                                    <div class="text" v-html="item.company_name"></div>
                                    <span>
                                        <img v-if="checkSelected(item.company_name_digest)" src="../../assets/img/h5/selected.png"/>
                                        <img v-else src="../../assets/img/h5/unselected.png"/>
                                    </span>
                                </div>
                                <div class="info">
                                    <div class="info_item">
                                        <span class="title">法人</span>
                                        <span>{{item.legal_person}}</span>
                                    </div>
                                    <div class="info_item">
                                        <span class="title">注册资本</span>
                                        <span>{{item.capital}}</span>
                                    </div>
                                    <div class="info_item">
                                        <span class="title">成立时间</span>
                                        <span>{{item.establish_date}}</span>
                                    </div>
                                </div>
                                <div class="platform">
                                    <span class="title">推广平台：</span>
                                    <span v-if="item.hasNAd">{{item.nAdPlatform.length}}个</span>
                                    <span v-else>该企业暂无推广信息</span>
                                </div>
                            </div>
                        </div>
                        <div class="compete" :class="item.showCompete?'open':''">
                            <div class="open_btn" @click.stop="getCompete(item)">
                                <span>竞品公司</span>
                                <img class="triangle" src="../../assets/img/h5/triangle.png"/>
                            </div>
                            <div class="compete_list" :class="item.showCompete?'active':''">
                                <div class="compete_item" v-for="(el, i) in item.competeList.rows" :key="i"  @click.stop="changeSelected(el, true)">
                                    <span class="name">{{el.compete_company_name}}</span>
                                    <span>{{el.compete_word_cnt}}相同词</span>
                                    <span>
                                        <img v-if="checkSelected(el.company_name_digest)" src="../../assets/img/h5/selected.png"/>
                                        <img v-else src="../../assets/img/h5/unselected.png"/>
                                    </span>
                                </div>
                                <div class="chang_btn" v-if="item.competeList.real_cnt > 5" @click.stop="changeCompetePage(item)">换一批</div>
                            </div>
                        </div>
                    </div>
                </template>
                </van-list>
            </van-pull-refresh>
            <div class="archival_information" v-if="tableData.length">
                <div>Copyright© 2021-2024 上海凭安网络科技有限公司 AI竞投（aijingtou.com）All Rights Reserved</div>
                <div>沪ICP备12039960号-38</div>
            </div>
            <div v-if="!key_word" class="empty">
                请输入关键词搜索对比分析的公司
            </div>
            <div class="footer">
                <div class="to_compare" @click="handleCompare">开始对比分析</div>
            </div>
        </template>
    </my-layout>
</template>

<script>
  import {Field, List, PullRefresh, Toast} from 'vant';
  import * as api from '@/api/mobile'
  import PinganAna from "pingansec-vue-ana";
  export default {
    components: {
      [Field.name]: Field,
      [List.name]: List,
      [PullRefresh.name]: PullRefresh,
      [Toast.name]: Toast,
    },
    name: "compare",
    data() {
      return {
        key_word: '',
        listPage: 1,
        listLimit: 10,
        tableData: [],
        loading: false,
        finished: true,
        refreshing: false,
        selectedCompany: [],
      }
    },
    created() {
      PinganAna.fire(3019)
    },
    methods: {
      goHome() {
        this.$router.push('/h5')
        PinganAna.fire(3018)
      },
      handleCompare() {
        if(this.selectedCompany.length === 2) {
          this.toCompare().then(data => {
            PinganAna.fire(3020)
            this.$router.push({
              path: '/share_h5',
              query: {
                conpete_id: data.compete_id
              }
            })
          })
        } else {
          Toast({
            message: '请选择两家对比公司'
          })
        }
      },
      toCompare() {
        let params = {
          digest_a: this.selectedCompany[0],
          digest_b: this.selectedCompany[1]
        }
        let p = new Promise((resolve, reject) => {
          api.init_compete_id(params).then(res => {
            if(res.data.result_code == 0) {
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      checkSelected(digest) {
        let flag = false;
        if(this.selectedCompany.indexOf(digest) !== -1) {
          flag = true;
        }
        return flag;
      },
      changeSelected(item, compete = false) {
        if(!compete) {
          if(!item.hasNAd) {
            Toast({
              message: '当前选择企业无广告推广请重新选择'
            })
            return;
          }
        }
        if(!this.checkSelected(item.company_name_digest)) {
          if(this.selectedCompany.length >= 2) {
            Toast({
              message: '一次只能选择两个企业',
            })
            return;
          }
          this.selectedCompany.push(item.company_name_digest)
        } else {
          this.selectedCompany.splice(this.selectedCompany.findIndex(el => el === item.company_name_digest), 1)
        }
        if(!compete) {
          this.getCompete(item)
        }
      },
      onRefresh() {
        this.finished = false;
        this.loading = true;
        this.listPage = 1
        this.onLoad();
      },
      changeKeyWord() {
        this.selectedCompany = []
        this.listPage = 1;
        this.listLimit = 10;
        this.tableData =  [];
        this.onRefresh()
      },
      onLoad() {
        if (this.refreshing) {
          this.tableData = [];
          this.refreshing = false;
        }
        this.handleSearch().then(data =>{
          let new_data = data.rows.map((el) =>{
            return {
              ...el,
              showCompete: false,
              competeList: {
                rows: [],
                page: 1,
              },
            }
          })
          this.tableData = [...this.tableData, ...new_data]
          if(new_data.length < 10) {
            this.finished = true;
            return
          }
          this.loading = false;
          this.listPage++
          if (this.tableData.length >= 100) {
            this.finished = true;
          }
        })
      },
      changeCompetePage(item) {
        PinganAna.fire(3021)
        item.competeList.page >= 12?item.competeList.page=1:item.competeList.page++
        this.getCompeteByDigest(item.company_name_digest, item.competeList.page).then(data => {
          item.competeList.rows = data.rows
          item.competeList.real_cnt = parseInt(data.real_cnt)
        })
      },
      getCompete(item) {
        if(!item.showCompete) {
          this.getCompeteByDigest(item.company_name_digest, item.competeList.page).then(data => {
            item.competeList.rows = data.rows
            item.competeList.real_cnt = parseInt(data.real_cnt)
            if(item.competeList.real_cnt > 0) {
              item.showCompete = !item.showCompete;
            } else {
              Toast({
                message: '该公司暂无竞品公司信息'
              })
            }
          })
        } else {
          item.competeList = {
            rows: [],
            page: 1
          }
          item.showCompete = !item.showCompete;
        }
      },
      getCompeteByDigest(digest, page) {
        let params = {
          digest,
          page,
          limit: 5
        }
        let p = new Promise((resolve, reject) => {
          api.get_ad_compete_list(params).then(res => {
            if(res.data.result_code == 0) {
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      handleSearch() {
        let params = {
          keywords: this.key_word,
          page: this.listPage,
          limit: this.listLimit
        }
        let p = new Promise((resolve, reject) => {
          api.get_company_list(params).then(res => {
            if(res.data.result_code == 0) {
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      test() {
        console.log(this.selectedCompany);
      }
    }
  }
</script>

<style scoped lang="scss">
.compare_h5 {
    /*background-color: #f5f6f7;*/
   .header {
       display: flex;
       justify-content: space-between;
       align-items: center;
       padding: 43px 39px;
       font-size: 54px;
       color: #333333;
       font-weight: bold;
       background-color: #fff;
       border-bottom: 2px solid #f0f0f0;
       img  {
           width: 98px;
           height: 83px;
       }
       .title_text {
           position: relative;
           left: 40px;
       }
       .entrance {
           font-size: 42px;
           color: #1f81f8;
           font-weight: normal;
       }
   }
    .list_item {
        margin-top: 30px;
        background-color: #ffffff;
        padding: 60px 40px;
        .item_main {
            width: 100%;
            display: flex;
            flex-direction: row;
            .list_item_left {
                padding-right: 53px;
                .company_logo {
                    width: 96px;
                    height: 96px;
                    display: inline-flex;
                    font-size: 42px;
                    line-height: 48px;
                    color: #ffffff;
                    border-radius: 21px;
                    opacity: 0.6;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    padding: 32px 32px;
                }
            }
            .list_item_right {
                width: 100%;
                .company_name {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    font-size: 54px;
                    color: #333333;
                    .text {
                        display: inline-block;
                        width: 700px;
                        line-height: 65px;
                        /*overflow: hidden;*/
                        /*text-overflow: ellipsis;*/
                        /*white-space: nowrap;*/
                    }
                    img {
                        width: 42px;
                        height: 42px;
                    }
                }
                .info {
                    display: flex;
                    justify-content: space-between;
                    padding-left: 34px;
                    margin-top: 35px;
                    .info_item {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: center;
                        font-size: 36px;
                        color: #333333;
                        .title {
                            color: #999999;
                            margin-bottom: 26px;
                        }
                    }
                }
                .platform {
                    font-size: 36px;
                    color: #999999;
                    margin-top: 42px;
                    .title {
                        color: #333;
                        margin-bottom: 26px;
                    }
                }
            }
        }
        .compete {
            font-size: 36px;
            color: #333333;
            border-radius: 12px;
            background-color: #f5f6f7;
            margin-top: 37px;
            padding-bottom: 5px;
            .open_btn {
                display: flex;
                align-items: center;
                padding: 23px 0;
                padding-left: 42px;
                .triangle {
                    width: 8px;
                    height: 16px;
                    margin-left: 15px;
                    transition: .2s ease-in;
                    transform: rotate(0deg);
                }
            }
            .compete_list {
                width: calc(99% - 65px);
                margin: 0 auto;
                transform: scaleY(0);
                transition: all 0.3s;
                transform-origin: top center;
                background-color: #fff;
                border-radius: 12px;
            }
            .active{
                transform: scaleY(1);
                transition: all 0.3s;
                transform-origin: top center;
                padding: 37px;
                padding-right: 26px;
                .compete_item {
                    display: flex;
                    justify-content: space-between;
                    font-size: 36px;
                    color: #333333;
                    margin-top: 49px;
                    img {
                        width: 42px;
                        height: 42px;
                    }
                    .name {
                        width: 500px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .chang_btn {
                    display: inline-block;
                    position: relative;
                    left: 35%;
                    padding: 23px 57px;
                    font-size: 36px;
                    color: #1f81f8;
                    background-color: #ffffff;
                    border-radius: 12px;
                    border: solid 2px #1f81f8;
                    margin-top: 37px;
                }
            }
        }
        .open {
            .open_btn {
                .triangle {
                    transform: rotate(90deg);
                }
            }
        }
    }
    .archival_information {
        width: 100%;
        text-align: center;
        line-height: 60px;
        font-size: 36px;
        color: #999999;
        margin-bottom: 59px;
        margin-top: 68px;
    }
    .empty {
        text-align: center;
        font-size: 40px;
        color: #999999;
        margin-top: 500px;
    }
    .footer {
        position: fixed;
        bottom: 0;
        z-index: 20;
        width: calc(100% - 80px);
        background-color: #fff;
        padding: 30px 40px;
        .to_compare {
            font-size: 48px;
            background-color: #1f81f8;
            border-radius: 12px;
            color: #ffffff;
            padding: 42px 0;
            text-align: center;
        }
    }
}
</style>

<style lang="scss">
.search {
    padding: 40px 45px;
    background-color: #fff;
    .van-cell {
        padding: 28px 45px;
        background-color: #e4e9ef;
        border-radius: 80px;
        .van-field__left-icon {
            display: inline-flex;
            align-items: center;
            margin-right: 20px;
        }
        .van-field__control {
            color: #999999;
            font-size: 42px;
        }
        .input_img {
            width: 50px;
            height: 50px;
        }
    }
}
em {
    color: #ef3819;
}
</style>
